import React, { useEffect } from "react";
import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { lightTheme, darkTheme } from "./Theme";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Hotjar from "@hotjar/browser";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "font-awesome/css/font-awesome.min.css";

const Dashboard = lazy(() => import("./components/dashboard/dashboard"));
const Public = lazy(() => import("./components/public/Public"));
const PublicV2 = lazy(() => import("./components/public/PublicV2"));
const PublicV3 = lazy(() => import("./components/public/PublicV3"));
const Graphs = lazy(() => import("./Main/PublicGraphs/Analysis"));
// const Login = lazy(() => import("./components/login/login"));

function App(props: any) {
  const { type } = useSelector((state: any) => state.layout.theme);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // disable console logging)
      window.console.log = () => {};
    }
  }, []);

  useEffect(()=>{

    if(props.username === "ge_healthcare") {
      const siteId = 3430831;
      const hotjarVersion = 6;

      Hotjar.init(siteId, hotjarVersion);
    }
  },[props.username])

  React.useEffect(() => {
    if (
      props.messageTime &&
      props.messageTime - new Date().valueOf() > -20000
    ) {
      if (props.messageColor === "success") toast.success(props.message);
      else if (props.messageColor === "error") toast.error(props.message);
      else if (props.messageColor === "info") toast.info(props.message);
    }
  }, [props.messageTime]);

  return (
    <ThemeProvider theme={type === "light" ? lightTheme : darkTheme}>
      <Suspense fallback={<div></div>}>
        <Switch>
          {/* <Route path="/login" exact component={Login} /> */}
          <Route path="/t/t/:courierId/:trackingId" component={Public} />
          <Route
            path="/track/awb/:clientName/:courierId/:trackingId"
            component={Public}
          />
          <Route path="/track/awb/:clientName/:trackingId" component={Public} />
          <Route
            path="/track/new/awb/:clientName/:trackingId"
            component={PublicV2}
          />
          <Route path="/track/v3/:trackingId/:courierId" component={PublicV3} />
          <Route path="/track/v3/:trackingId" component={PublicV3} />
          <Route path="/public/graphs" exact component={Graphs} />
          <Route path="/" component={Dashboard} />
        </Switch>
      </Suspense>
      <ToastContainer />
    </ThemeProvider>
  );
}

const mapStateToProps = (state: any) => ({
  message: state.appData.snackbar.message,
  messageColor: state.appData.snackbar.color,
  messageTime: state.appData.snackbar.messageTime,
  clientId: state.auth.clientId,
  username: state.auth.username,
});

export default connect(mapStateToProps)(App);
