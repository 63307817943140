import {
  INIT_SHIPMENTS,
  INIT_MAIN_COUNTERS,
  INIT_FILTER_COUNTERS,
  SHOW_TOAST,
  INIT_FILTER_OPTIONS,
  UPDATE_SHIPMENT,
  INIT_PAYMENT_COUNTERS,
  UPDATE_REMARKS,
} from "../actions/types";
const initialState = {
  shipments: [],
  timeline: [],
  mainCounters: {
    tracked: "0",
    ontime_intransit: "0",
    ontime_intransit_change: 0,
    late_intransit: "0",
    late_intransit_change: 0,
    ontime_delivered: "0",
    ontime_delivered_change: 0,
    late_delivered: "0",
    late_delivered_change: 0,
    total_delivered: 0,
    total_intransit: 0,
    others_delivered_change: 0,
    others_intransit: 0,
    others_intransit_change: 0,
    others_delivered: 0,
  },
  filterCounters: [
    { label: "All Trips", value: 0 },
    { label: "Tracked", value: 0 },
    { label: "Booked", value: 0 },
    { label: "In Transit", value: 0 },
    { label: "Reached Destination", value: 0 },
    { label: "Out for Delivery", value: 0 },
    { label: "Delivered", value: 0 },
    { label: "Undelivered", value: 0 },
    { label: "Reversed", value: 0 },
    { label: "Disabled", value: 0 },
    { label: "Exception", value: 0 },
    { label: "No Info yet", value: 0 },
  ],
  snackbar: {
    message: "Test",
    color: "info",
    messageTime: null,
  },
  filterOptions: {},
  totalShipments: 0,
};
const reducer = (state = initialState, action: any) => {
  const { type } = action;
  switch (type) {
    case INIT_SHIPMENTS:
      return {
        ...state,
        shipments: Array.isArray(action.payload.results)
          ? action.payload.results
          : [],
        totalPODs: action.payload.total,
        verifiedPODs: action.payload.extra?.[0]?.verifiedPODs?.[0]?.count || 0,
        notVerifiedPODs:
          action.payload.extra?.[0]?.notVerifiedPODs?.[0]?.count || 0,
        verifiedPODsWithException:
          action.payload.extra?.[0]?.verifiedPODsWithException?.[0]?.count || 0,
        totalShipments: action.payload.total,
        totalPayments:
          action.payload.extra?.[0]?.totalShipments?.[0]?.count || 0,
        withVariance: action.payload.extra?.[0]?.withVariance?.[0]?.count || 0,
        withoutVariance:
          (action.payload.extra?.[0]?.totalShipments?.[0]?.count || 0) -
          (action.payload.extra?.[0]?.withVariance?.[0]?.count || 0),
      };
    case INIT_MAIN_COUNTERS:
      return {
        ...state,
        mainCounters: action.payload,
      };
    case INIT_FILTER_COUNTERS:
      return {
        ...state,
        filterCounters: action.payload.filterCounters,
        mainCounters: action.payload.mainCounters,
      };
    case SHOW_TOAST:
      return {
        ...state,
        snackbar: action.payload,
      };
    case INIT_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: action.payload,
      };
    case UPDATE_SHIPMENT:
      return {
        ...state,
        shipments: action.payload,
      };
    case INIT_PAYMENT_COUNTERS:
      return {
        ...state,
        paymentCounters: action.payload,
      };
    case UPDATE_REMARKS: {
      const { shipmentId, trackingId, remarks } = action.payload;
      const index = state.shipments.findIndex(
        (item: any) =>
          shipmentId === item.shipmentId && trackingId === item?.trackingId
      );
      let newShipments: any[] = [...state.shipments];
      if (newShipments[index].remarks)
        newShipments[index].remarks.push(remarks);
      else {
        newShipments[index].remarks = [remarks];
      }
      return {
        ...state,
        shipments: newShipments,
      };
    }
    default:
      return state;
  }
};
export default reducer;
