export const TOGGLE_SIDENAV = "TOGGLE_SIDENAV";
export const TOGGLE_THEME = "TOGGLE_THEME";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const INIT_SHIPMENTS = "INIT_SHIPMENTS";
export const INIT_MAIN_COUNTERS = "INIT_MAIN_COUNTERS";
export const INIT_FILTER_COUNTERS = "INIT_FILTER_COUNTERS";
export const SHOW_TOAST = "SHOW_TOAST";
export const INIT_CONFIG = "INIT_CONFIG";
export const INIT_SOURCES = "INIT_SOURCES";
export const INIT_DESTS = "INIT_DESTS";
export const INIT_FILTER_OPTIONS = "INIT_FILTER_OPTIONS";
export const UPDATE_SHIPMENT = "UPDATE_SHIPMENT";
export const TOGGLE_SETTINGS_SIDENAV = "TOGGLE_SETTINGS_SIDENAV";
export const INIT_PAYMENT_COUNTERS = "INIT_PAYMENT_COUNTERS";
export const UPDATE_REMARKS = "UPDATE_REMARKS";

// Master data const

export const START_LOADER = "START_LOADER";
export const API_ERROR = "API_ERROR";

// Transporter
export const FETCH_TRANSPORTER_MASTER_DATA = "FETCH_TRANSPORTER_MASTER_DATA";
export const FETCH_TRANSPORTER_MASTER_DATA_SUCCESS =
  "FETCH_TRANSPORTER_MASTER_DATA_SUCCESS";
export const FETCH_TRANSPORTER_MASTER_DATA_FAIL =
  "FETCH_TRANSPORTER_MASTER_DATA_FAIL";

export const DELETE_MASTER_DATA = "DELETE_MASTER_DATA";
export const DELETE_MASTER_DATA_SUCCESS = "DELETE_MASTER_DATA_SUCCESS";
export const DELETE_MASTER_DATA_FAIL = "DELETE_MASTER_DATA_FAIL";

// Payment
export const FETCH_PAYMENT_MASTER_DATA = "FETCH_PAYMENT_MASTER_DATA";
export const FETCH_PAYMENT_MASTER_DATA_SUCCESS =
  "FETCH_PAYMENT_MASTER_DATA_SUCCESS";
export const FETCH_PAYMENT_MASTER_DATA_FAIL = "FETCH_PAYMENT_MASTER_DATA_FAIL";

export const UPDATE_PAYMENT_MASTER_DATA = "UPDATE_PAYMENT_MASTER_DATA";
export const UPDATE_PAYMENT_MASTER_DATA_SUCCESS =
  "UPDATE_PAYMENT_MASTER_DATA_SUCCESS";
export const UPDATE_PAYMENT_MASTER_DATA_FAIL =
  "UPDATE_PAYMENT_MASTER_DATA_FAIL";
export const ADD_PAYMENT_FOR_RECALCULATION = "ADD_PAYMENT_FOR_RECALCULATION";
export const REMOVE_PAYMENT_FOR_RECALCULATION =
  "REMOVE_PAYMENT_FOR_RECALCULATION";

// Customer
export const FETCH_CUSTOMERS_MASTER_DATA = "FETCH_CUSTOMERS_MASTER_DATA";
export const FETCH_CUSTOMERS_MASTER_DATA_SUCCESS =
  "FETCH_CUSTOMERS_MASTER_DATA_SUCCESS";
export const FETCH_CUSTOMERS_MASTER_DATA_FAIL =
  "FETCH_CUSTOMERS_MASTER_DATA_FAIL";

export const UPDATE_CUSTOMER_MASTER_DATA = "UPDATE_CUSTOMER_MASTER_DATA";
export const UPDATE_CUSTOMER_MASTER_DATA_SUCCESS =
  "UPDATE_CUSTOMER_MASTER_DATA_SUCCESS";
export const UPDATE_CUSTOMER_MASTER_DATA_FAIL =
  "UPDATE_CUSTOMER_MASTER_DATA_FAIL";

// Lane
export const FETCH_LANE_MASTER_DATA = "FETCH_LANE_MASTER_DATA";
export const FETCH_LANE_MASTER_DATA_SUCCESS = "FETCH_LANE_MASTER_DATA_SUCCESS";
export const FETCH_LANE_MASTER_DATA_FAIL = "FETCH_LANE_MASTER_DATA_FAIL";

export const UPDATE_LANE_MASTER_DATA = "UPDATE_LANE_MASTER_DATA";
export const UPDATE_LANE_MASTER_DATA_SUCCESS =
  "UPDATE_LANE_MASTER_DATA_SUCCESS";
export const UPDATE_LANE_MASTER_DATA_FAIL = "UPDATE_LANE_MASTER_DATA_FAIL";

export const ADD_NEW_SUCCESS = "ADD_NEW_SUCCESS";
export const ADD_LANE_FOR_RECALCULATION = "ADD_LANE_FOR_RECALCULATION";
export const REMOVE_LANE_FOR_RECALCULATION = "REMOVE_LANE_FOR_RECALCULATION";
// Feedback

export const UPDATE_CALENDAR = "UPDATE_CALENDAR";

//Indent
export const UPDATE_INDENT_FORM = "UPDATE_INDENT_FORM";
