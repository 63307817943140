import { UPDATE_INDENT_FORM } from "../actions/types";
const initialState = {
  formData: {},
};
const reducer = (state = initialState, action: any) => {
  const { type } = action;
  switch (type) {
    case UPDATE_INDENT_FORM:
      return {
        ...state,
        formData: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
