import {
  TOGGLE_SIDENAV,
  TOGGLE_THEME,
  TOGGLE_SETTINGS_SIDENAV,
  FETCH_TRANSPORTER_MASTER_DATA_SUCCESS
} from "../actions/types";
const initialState = {
  theme: {
    type: "light",
  },
  version: "1.18",
  sideNav: {
    expanded: true,
    settingsNav: false,
    callToActionButton: {
      text: "Add New Trip",
      size: "medium",
      color: "success",
    },
    announcement: {
      title: "Learn more about our services",
      content:
        "We offer a bunch of products to help improve your logistics supply chain",
      action: {
        text: "Learn More",
        variant: "outlined",
        size: "small",
      },
    },
    navigations: [
      {
        title: "Navigation One",
        navItems: [
          {
            name: "Home",
            path: "/",
            icon: "HomeNavIcon",
            component: "Home",
          },
        ],
      },
      {
        title: "Navigation Two",
        navItems: [
          {
            name: "Help",
            icon: "HelpIcon",
            path: "/help",
            component: "CommingSoon",
          },
        ],
      },
    ],
    settingsExpandableNavigation: [
      {
        name: "General Settings",
        path: "/generalSettings",
        icon: "settings",
        children: [
          {
            name: "Profile",
            path: "/profile",
          },
          {
            name: "General Details",
            path: "/generalDetails",
          },
          {
            name: "Address Details",
            path: "/addressDetails",
          },
          // {
          //   name: "Accounting & Bank Details",
          //   path: "/accountingAndBankDetails",
          // },
          {
            name: "API Keys",
            path: "/APIKeys",
          },
        ],
      },
    ],
    footer: {
      tagLine: "Powered by Intugine",
      brandLogo: require("../../assets/Intugine.png").default,
    },
  },
  header: {},
  transporterMasterData: []
};
const reducer = (state = initialState, action: any) => {
  const { type } = action;
  switch (type) {
    case TOGGLE_SIDENAV:
      return {
        ...state,
        sideNav: { ...state.sideNav, expanded: !state.sideNav.expanded },
      };
    case TOGGLE_SETTINGS_SIDENAV:
      return {
        ...state,
        sideNav: { ...state.sideNav, settingsNav: !state.sideNav.settingsNav },
      };
    case TOGGLE_THEME:
      return {
        ...state,
        theme: {
          ...state.theme,
          type: state.theme.type === "light" ? "dark" : "light",
        },
      };

    case FETCH_TRANSPORTER_MASTER_DATA_SUCCESS: 
    return {
      ...state,
      transporterMasterData: action?.payload?.data,
    };

    // case FETCH_TRANSPORTER_MASTER_DATA_FAIL:
    //   return {
    //     ...state,
    //     transporterMasterData: action.payload,
    //   };

    default:
      return state;
  }
};
export default reducer;
