import { LOGIN, LOGOUT } from "../actions/types";
const initialState = {
  loggedIn: false,
  username: null,
  clientName: null,
  clientId: null,
  courierAccountId: null,
  token: null,
  config: {},
  shouldLogout: false,
};
const reducer = (state = initialState, action: any) => {
  const { type } = action;
  switch (type) {
    case LOGIN:
      return {
        ...state,
        loggedIn: true,
        username: action.payload.username || "Guest",
        clientName: action.payload.clientName || "Guest",
        clientId: action.payload.clientId || "Guest",
        token: action.payload.token,
        courierAccountId: action.payload.courierAccountId,
        config: action.payload.config,
      };
    case LOGOUT:
      return {
        ...state,
        shouldLogout: true,
      };
    default:
      return state;
  }
};
export default reducer;
