import { combineReducers } from "redux";
import layout from "./layout";
import auth from "./auth";
import appData from "./appData";
import master from "./master";
import feedback from "./feedback";
import indent from "./indent";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const appDataPersistConfig = {
  key: "authData",
  storage,
  blacklist: ['snackbar']
};

const rootReducer = combineReducers({
  layout,
  auth,
  appData: persistReducer(appDataPersistConfig, appData),
  master,
  feedback,
  indent,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
