import {
  FETCH_CUSTOMERS_MASTER_DATA_SUCCESS,
  FETCH_LANE_MASTER_DATA_SUCCESS,
  FETCH_PAYMENT_MASTER_DATA_SUCCESS,
  FETCH_TRANSPORTER_MASTER_DATA_SUCCESS,
  UPDATE_PAYMENT_MASTER_DATA_FAIL,
  UPDATE_PAYMENT_MASTER_DATA_SUCCESS,
  UPDATE_CUSTOMER_MASTER_DATA_SUCCESS,
  UPDATE_CUSTOMER_MASTER_DATA_FAIL,
  DELETE_MASTER_DATA_SUCCESS,
  DELETE_MASTER_DATA_FAIL,
  UPDATE_LANE_MASTER_DATA_SUCCESS,
  UPDATE_LANE_MASTER_DATA_FAIL,
  START_LOADER,
  API_ERROR,
  ADD_NEW_SUCCESS,
  ADD_PAYMENT_FOR_RECALCULATION,
  REMOVE_PAYMENT_FOR_RECALCULATION,
  ADD_LANE_FOR_RECALCULATION,
  REMOVE_LANE_FOR_RECALCULATION,
} from "../actions/types";
const initialState = {
  transporterMasterData: [],
  paymentMasterData: [],
  laneMasterData: [],
  customersMasterData: [],
  transporterMasterDataResults: [],
  paymentUnderRecalculation: {},
  laneUnderRecalculation: {},
  updatePaymentResult: null,
  // updateCustomerResult: null,
  updateResult: null,
  loading: null,
  apiError: null,
  addNewSuccess: null,
};
const reducer = (state = initialState, action: any) => {
  const { type } = action;
  switch (type) {
    case START_LOADER:
      return {
        ...state,
        loading: action?.data,
      };

    case API_ERROR:
      return {
        ...state,
        apiError: action?.data,
      };

    case ADD_NEW_SUCCESS:
      return {
        ...state,
        addNewSuccess: action?.payload?.data,
      };

    case FETCH_TRANSPORTER_MASTER_DATA_SUCCESS:
      return {
        ...state,
        transporterMasterData: action?.payload?.data,
        // transporterMasterDataResults: action?.payload?.data?.results
        // transporterMasterDataResults: [...state.transporterMasterDataResults,action?.payload?.data?.results]

        // transporterMasterDataResults: []
      };

    case FETCH_PAYMENT_MASTER_DATA_SUCCESS:
      return {
        ...state,
        paymentMasterData: action?.payload?.data,
        loading: false,
      };

    case FETCH_CUSTOMERS_MASTER_DATA_SUCCESS:
      return {
        ...state,
        customersMasterData: action?.payload?.data,
        loading: false,
      };

    case FETCH_LANE_MASTER_DATA_SUCCESS:
      return {
        ...state,
        laneMasterData: action?.payload?.data,
        loading: false,
      };

    case UPDATE_PAYMENT_MASTER_DATA_SUCCESS:
      return {
        ...state,
        updatePaymentResult: action?.payload?.data,
      };

    case UPDATE_PAYMENT_MASTER_DATA_FAIL:
      return {
        ...state,
        updatePaymentResult: action?.payload,
      };

    case UPDATE_CUSTOMER_MASTER_DATA_SUCCESS:
      return {
        ...state,
        updateResult: action?.payload?.data,
      };

    case UPDATE_CUSTOMER_MASTER_DATA_FAIL:
      return {
        ...state,
        updateResult: action?.payload,
      };

    case DELETE_MASTER_DATA_SUCCESS:
      return {
        ...state,
        updateResult: action?.payload?.data,
      };

    case DELETE_MASTER_DATA_FAIL:
      return {
        ...state,
        updateResult: action?.payload,
      };

    case UPDATE_LANE_MASTER_DATA_SUCCESS:
      return {
        ...state,
        updateResult: action?.payload?.data,
      };

    case UPDATE_LANE_MASTER_DATA_FAIL:
      return {
        ...state,
        updateResult: action?.payload,
      };

    case ADD_PAYMENT_FOR_RECALCULATION:
      return {
        ...state,
        paymentUnderRecalculation: {
          ...state.paymentUnderRecalculation,
          [action.payload]: true,
        },
      };
    case REMOVE_PAYMENT_FOR_RECALCULATION:
      return {
        ...state,
        paymentUnderRecalculation: {
          ...state.paymentUnderRecalculation,
          [action.payload]: false,
        },
      };
    case ADD_LANE_FOR_RECALCULATION:
      return {
        ...state,
        laneUnderRecalculation: {
          ...state.laneUnderRecalculation,
          [action.payload]: true,
        },
      };
    case REMOVE_LANE_FOR_RECALCULATION:
      return {
        ...state,
        laneUnderRecalculation: {
          ...state.laneUnderRecalculation,
          [action.payload]: false,
        },
      };
    // case FETCH_TRANSPORTER_MASTER_DATA_FAIL:
    //   return {
    //     ...state,
    //     transporterMasterData: action.payload,
    //   };

    default:
      return state;
  }
};
export default reducer;
