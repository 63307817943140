import { endOfMonth, startOfMonth } from "date-fns";
import { UPDATE_CALENDAR } from "../actions/types";

const initialState = {
  common: {
    startDate: startOfMonth(new Date()).getTime(),
    endDate: endOfMonth(new Date()).getTime(),
  },
};

const reducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CALENDAR:
      return {
        ...state,
        common: {
          ...state.common,
          startDate: payload.startDate,
          endDate: payload.endDate,
        },
      };
    default:
      return state;
  }
};
export default reducer;
